.notes-panel-style {
  width: 480px;
}

.notes-panel-style .notes-panel-header {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 16px;
  background: #F5F7F7;
}

.notes-panel-style .notes-panel-header .note-dropdown-status .dropdown-select-style {
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 186px !important;
}

.notes-panel-style .notes-panel-header .note-dropdown-incident .dropdown-select-style {
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 250px !important;
}

.notes-panel-style .notes-panel-content {
  background: #FFFFFF;
  padding: 0px 16px;
  overflow-y: auto;
  height: calc(100vh - 361px);
  overflow-x: hidden;
}

.notes-panel-content .btn-view-call-log {
  position: sticky;
  bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.notes-panel-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #D6D8D9;
  border-radius: 4px;
  background-color: #F5F5F5;
}

.notes-panel-content::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

.notes-panel-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px #D6D8D9;
  background-color: #888888;
}

.notes-panel-content .notes-panel-box {
  margin-bottom: 8px;
  padding-top: 8px;
  position: relative;
}

.notes-panel-content .notes-panel-box .note-detail-label {
  position: absolute;
  top: 0;
  pointer-events: none;
  left: 12px;
  padding: 0 5px;
  transition: 0.2s ease all;
  background: #fff;
}

.notes-panel-content .notes-panel-box textarea {
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px 16px 8px 16px;
  min-height: 56px;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Inter';
}

.notes-panel-content .notes-panel-box textarea:focus-visible {
  border: 1px solid #007FA1;
}


.notes-panel-content .btn-add-note {
  margin-bottom: 24px;
}


@media (max-width: 1440px) {
  .notes-panel-style {
    width: 384px;
  }
  .notes-panel-style .notes-panel-header{
    padding: 8px 12px;
  }
  .notes-panel-style .notes-panel-header .note-dropdown-status .dropdown-select-style {
    width: 145px !important;
  }

  .notes-panel-style .notes-panel-header .note-dropdown-incident .dropdown-select-style {
    width: 205px !important;
  }
}
.user-info-panel-style {
    background: #F5F7F7;
    padding: 16px 24px 12px;
    gap: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D6D8D9;
}

.user-info-left-style {
    display: flex;
    gap: 16px;
    width: calc(100% - 580px);
}

.date-time-style {
    display: flex;
}

.call-button-style {
    display: flex;
    gap: 8px;
}

.call-button-child-style {
    text-align: center;
    height: 40px;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #D6D8D9;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 8px;
    flex-direction: row;
    padding: 15px 20px;
    cursor: pointer;
}

.call-button-child-style:hover  {
    border: 1px solid #0DA9B9;
}

.call-button-child-style:active  {
    border: 1px solid #0DA9B9;
    background: #E7F6F8;
}

.call-button-child-style .btn-image {
    display: flex;
}

.call-button-child-style.chat,
.call-button-child-style.mobile,
.call-button-child-style.video,
.call-button-child-style.home,
.call-button-child-style.work {
    border: none;
    background: #007FA1;
}


.call-button-child-style.work .btn-image svg path,
.call-button-child-style.mobile .btn-image svg path,
.call-button-child-style.video .btn-image svg path,
.call-button-child-style.home .btn-image svg path,
.call-button-child-style.chat .btn-image svg path  {
    stroke: #FFFFFF;
}

.call-status-label{
    margin: auto;
    padding: 10px;
    text-transform: capitalize;
}

@media (max-width: 1440px) {
    .call-button-style {
        gap: 15px;
    }
    .call-button-style .call-button-child-style {
        display: flex;
        flex-direction: column;
        border: none;
        background: none;
        box-shadow: none;
        height: auto;
        width: auto;
        padding: 0;
    }
    .call-button-style .call-button-child-style .text-style {
        color: #000000 !important;
    }
    .call-button-style .call-button-child-style .btn-image {
        border: 1px solid #D6D8D9;
        box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        align-content: center;
        padding: 10px 15px;
    }
    .call-button-style .call-button-child-style .btn-image:hover {
        border: 1px solid #0DA9B9;
    }
    .call-button-style .call-button-child-style .btn-image:active {
        border: 1px solid #0DA9B9;
        background: #E7F6F8;
    }
    .call-button-child-style.chat .btn-image,
    .call-button-child-style.mobile .btn-image,
    .call-button-child-style.video .btn-image,
    .call-button-child-style.home .btn-image,
    .call-button-child-style.work .btn-image {
        border: none;
        background: #007FA1;
    }

    .call-button-child-style.work .btn-image svg path,
    .call-button-child-style.mobile .btn-image svg path,
    .call-button-child-style.video .btn-image svg path,
    .call-button-child-style.home .btn-image svg path,
    .call-button-child-style.chat .btn-image svg path  {
        stroke: #FFFFFF;
    }
}
.avatar-outer-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}
.avatar-wrap {
  position: relative;
}
.avatar-caller-image {
  position: absolute;
  top: 25px;
  left: 25px;
}
.avatar-name{
  margin-top: 10px;
  width: auto;
  height: 18px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

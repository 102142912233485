.switcher-button-wrap {
  width: fit-content;
  display: flex;
  overflow: hidden;
  border: 1px solid #9DD9E0;
  border-radius: 10px;
}

.switcher-button-wrap .button-style-primary{
  padding: 6px 23px;
}
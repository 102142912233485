.current-location-marker .marker-wrap{
  left: -38px;
  top: -38px;
  width: 76px;
  height: 76px;
  display: flex;
}
.current-location-marker .current-location-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  top: -80px;
  left: -28.5px;
  z-index: 1;
  position: absolute;
}


.current-location-marker .current-time {
  background: #FFFFFF;
  display: flex;
  border-radius: 80px;
  padding: 3px 10px;
  position: absolute;
  bottom: -12px;
  z-index: 10;
  flex-direction: row;
  align-items: center;
}

.current-location-marker .pointer{
  position: absolute;
  width: 14.39px;
  height: 14.39px;
  bottom: -17px;
  background: #FFFFFF;
  border-radius: 2px;
  transform: rotate(45deg);
}
.footer-style {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 64px;
  border-top: 1px solid #D6D8D9;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.footer-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-right .caller-hold-status {
  margin-left: 12px;
  pointer-events: none;
}
.caller-style {
  height: calc(100vh - 120px);
  width: 100%;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.caller-style .caller-left {
  height: inherit;
  width: 460px;
  position: relative;
}

.caller-style.hide-caller-left {
  left: -460px;
  width: calc(100% + 460px);
}

.caller-style.hide-caller-left .map-container .map-control {
  width: 100%;
}

.caller-style .caller-left .chat-conversation-section {
  height: inherit;
  position: relative;
}

.caller-style.hide-caller-left .caller-right {
  background: #fff;
}

.caller-style .caller-right {
  width: calc(100% - 460px);
  height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.caller-style .caller-right .caller-navigation {
  height: calc(100% - 100px);
  position: relative;
}

.caller-style .caller-right .caller-navigation .caller-navigation-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  gap: 301px;
  height: 44px;
  background: #FFFFFF;
  border-bottom: 1px solid #D6D8D9;
}

.caller-notes-panel {
  background: #FFFFFF;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.2);
  right: 0;
  height: calc(100vh - 264px);
  position: absolute;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  z-index: 11;
}

.caller-notes-panel .notes-panel-style .notes-panel-content {
  max-height: calc(100vh - 351px);
}

.caller-details-left {
  width: calc(100% - 217px);
}

.caller-navigation-right {
  margin-left: 32px;
  width: 170px;
  margin-top: 8px;
}

.map-detail .map-fullscreen {
  position: absolute;
  top: calc(40% - 35px);
  right: 5px;
  display: flex;
  border: 0px;
  margin: 5px;
  cursor: pointer;
  border-radius: 2px;
  height: 25px;
  width: 25px;
  bottom: 0px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
  justify-content: center;
  align-content: center;
  align-items: center;
}

.caller-navigation-right .caller-navigations {
  position: fixed;
  width: 170px;
}

/* Details */
#general-notes {
  padding-bottom: 5px;
}

.info-detail-container {
  height: calc(100vh - 264px);
  position: relative;
  width: 100%;
}

.caller-style .caller-right .user-info-panel-style {
  height: 100px;
}
.caller-style .caller-right .user-info-panel-style .user-info-panel-child-style {
  position: relative;
  width: calc(100% - 96px);
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
}
.caller-style .caller-right .user-info-panel-style .user-info-panel-child-style .text-style:nth-child(2){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.caller-style .caller-right .caller-navigation-content .info-detail {
  height: calc(100vh - 60% - 264px);
  overflow-y: auto;
  display: flex;
  position: relative;
  width: 100%;
  padding-left: 16px;
}
.caller-style .caller-right .caller-navigation-content .info-detail-container.set-map-minimize .info-detail {
  height: calc(100vh - 264px);
}

.caller-style .caller-right .caller-navigation-content .info-detail-container.set-map-minimize .map-detail .map-container {
  display: none;
}

.caller-style .caller-right .caller-navigation-content .info-detail-container.set-map-minimize .map-fullscreen{
  top: calc(100% - 35px);
}
/* Contact */

.caller-navigation-content .contact-content {
  height: calc(100vh - 264px);
  position: relative;
  width: 100%;
  padding: 20px;
  overflow-x: auto;
}

.caller-navigation-content .contact-content .contact-table {
  min-width: 1016px;
  position: relative;
  overflow: hidden;
  height: 100%;
  overflow-x: auto;
}

.caller-style .caller-right .caller-navigation-content .contact-container.set-map-minimize .map-detail .map-container {
  display: none;
}

.caller-style .caller-right .caller-navigation-content .contact-container.set-map-minimize .map-fullscreen{
  top: calc(100% - 35px);
}

.caller-navigation-content .set-map-fullscreen .contact-content .contact-table .contact-table-content {
  height: calc(100% - 60% - 70px);
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.caller-navigation-content .set-map-minimize .contact-content .contact-table .contact-table-content {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.history-notes-table .table-header-style .header-title:nth-child(4), .caller-navigation-content .contact-table-column.contact {
  margin-right: 10px;
}

.contact-table .table-header-style .header-title:nth-child(4), .caller-navigation-content .contact-table-column.contact {
  width: calc(15% + 20px) !important;
}

.contact-table .table-header-style .header-title:nth-child(5), .caller-navigation-content .contact-table-column.contact-address {
  width: calc(25% - 20px) !important;
  margin-left: 0.5% !important;
}

.caller-navigation-content .contact-table-column.contact-address {
  display: block;
}

.contact-table .table-header-style .header-title {
  margin-right: 0.5%;
}

.contact-table-content .contact-table-row .contact-table-column {
  margin-right: 0.5%;
}

.history-notes-table .table-header-style .header-title:nth-child(4), .caller-navigation-content .contact-table-column.contact {
  margin-right: 0px;
}

.contact-table .table-header-style .header-title:nth-child(7) {
  cursor: pointer;
}
.contact .button-style-image-text{
  padding: 0px !important;
}

.caller-navigation-content .contact-table-row {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #D6D8D9;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 2px;
}

.caller-navigation-content .contact-table-column {
  display: flex;
  align-items: center;
  position: relative;
}

.caller-navigation-content .contact-table-column.distance {
  justify-content: space-between;
}

.caller-navigation-content .contact-table-column .circle-image-style {
  margin-right: 16px;
}

.contact-options {
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  border-radius: 8px;
  color: #007FA1;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.contact-options:hover {
  border: 1px solid #0DA9B9;
  box-sizing: border-box;
}

.contact-method {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 3;
  margin-top: -190px;
  margin-left: -110px;
}

.close-open-conversation {
  background: #FFFFFF;
  position: absolute;
  right: -20px;
  bottom: 35%;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid #D6D8D9;
  width: 20px;
  height: 35px;
  display: flex;
  align-content: center;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 100;
}

.contact-info-panel {
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.2);
  z-index: 10;
  box-sizing: border-box;
  height: calc(100vh - 264px);
  right: 0;
}

.contact-info-panel.hide {
  animation: hide;
  -webkit-animation-duration: .5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
}

.contact-info-panel.show {
  animation: show;
  -webkit-animation-duration: .5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
}

@keyframes hide {
  from {
    width: 384px;
  }
  to {
    width: 0;
  }
}

@keyframes show {
  from {
    width: 0;
  }
  to {
    width: 384px;
  }
}


.contact-info-panel .contact-information-style .contact-information-content {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 550px);
  padding: 20px;
}

.contact-info-panel .contact-information-style .contact-information-content::-webkit-scrollbar {
  width: 0px;
}

/* History & Notes */
.history-notes-style {
  overflow-x: auto;
}

.history-notes-style .history-notes-table .table-content {
  max-height: calc(100vh - 338px);
  overflow-y: auto;
}

.caller-notes-panel-history {
  position: relative;
  float: right;
  background: #FFFFFF;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.2);
  height: calc(100vh - 337px);
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
}

.history-notes-style .history-notes-table {
  min-width: 923px;
  padding: 0 24px;
}

.history-notes-style .history-notes-table .table-row-content:hover {
  background: #F2F9FA;
  border: 1px solid #0DA9B9;
}

.history-notes-style .history-notes-table .table-row-content.active {
  background: #F2F9FA;
  border: 1px solid #0DA9B9;
  color: #000000;
}

.history-notes-style .history-notes-table .table-row-content.row-incident{
  margin-left: 8px;
  border-radius: 3px 0px 0px 3px;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #ABB1B2;
}

.history-notes-style .history-notes-table .table-row-content .header-title {
  padding-right: 10px;
}

.history-notes-style .history-notes-table .table-row-content .header-title.service {
  width: 8%;
}

.history-notes-style .history-notes-table .table-row-content .header-title.type {
  width: 5%;
}

.history-notes-style .history-notes-table .table-row-content .header-title.contact {
  width: 12%;
}

.history-notes-style .history-notes-table .table-row-content .header-title.location {
  width: 35%;
}

.history-notes-style .history-notes-table .table-row-content .header-title.received {
  width: 15%;
}

.history-notes-style .history-notes-table .table-row-content .header-title.duration {
  width: 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.history-notes-style .history-notes-table .table-row-content .header-title.info {
  width: 10%;
}

.history-notes-style .history-notes-table .table-row-content .header-title.call-ref {
  display: flex;
  justify-content: space-between;
  width: 13%;
}

.header-title .info-icon {
  display: flex;
}

.header-title .info-icon svg path {
  stroke: #000000;
}

/* common */

.empty {
  background: #D6D8D9;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 8px;
  font-weight: 700;
  font-size: 11px;
  color: #000000;
}

@media (max-width: 1440px) {
  .caller-style .caller-left {
    width: 370px;
  }

  .caller-style.hide-caller-left {
    left: -370px;
    width: calc(100% + 370px);
  }

  .caller-style .caller-right {
    width: calc(100% - 370px);
  }
}

.caller-header-icon {
  cursor: pointer;
}

.status-buttons .button-style-image-text{
  border: 1px solid #007fa1;
}

.history-notes-style .data-toggle{
  padding: 0 24px;
  font-size: 12px;
  margin: 8px 0px;
}

.history-notes-style .data-toggle span{
  float: left;
  margin-right: 8px;
}

.history-notes-style .data-toggle .checkbox-style{
  width: 18px;
  height: 18px;
}

.interaction-history-spinner-wrap{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.value-not-available{
  text-align: center;
  width: 52px;
  height: 24px;
  line-height: 25px;
  font-weight: 700 !important;
  font-size: 11px !important;
  background: #D6D8D9;
  border-radius: 3px; 
}

.image-animation-wrap{
  padding-top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.audio-player-call-address{
  position: absolute;
  bottom: 140px;
  width: 100%;
  text-align: center;
}


.audio-player-call-datetime{
  position: absolute;
  bottom: 112px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.audio-control{
  position: absolute;
  bottom: 0px;
  background-color: #2C2E2E;
}

.ripple {
  margin: auto;
  background-color: #fff;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: grid;
  animation: ripple 3s linear infinite;
  background-color: #9dd9e0;
}

.ripple::before,
.ripple::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  animation: inherit;
  animation-delay: 1s;
  width: 96px;
  height: 96px;

}

.ripple::after {
  animation-delay: 2s;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0.7rem #9dd9e0;
  }

  100% {
    box-shadow: 0 0 0 8rem rgba(255, 255, 255, 0);
  }
}
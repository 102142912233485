.title-style {
  background: #F5F7F7;
  color: #000;
  padding: 10px 15px;
  text-align: left;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.edit-caller-style {
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 23px;
  position: absolute;
  right: -16px;
  top: 8px;
  cursor: pointer;
}
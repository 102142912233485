.search-bar-text-wrap {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: inherit;
}

.search-bar-text-wrap .input-labelled-wrap {
  background: #FFFFFF;
  border: 1px solid #9DD9E0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.search-bar-text-wrap.focused .input-labelled-wrap{
  border: 2px solid #0DA9B9;
}

.search-bar-wrap {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 40px;
  width: -webkit-fill-available;
}

.search-bar-outer-wrap {
  background: #F5F7F7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}
.general-notes-style {
    border: 1px solid #D6D8D9;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 8px 0px;
    padding: 0px 5px 5px 5px;
}

.general-notes-style .general-notes {
    margin-top: 8px;
}

.general-notes-style .general-notes textarea {
    border-color: #D6D8D9;
}

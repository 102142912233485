.profile-image-style {
  position: relative;
}

.profile-image-style .circle-image-style {
  height: 80px;
}

.profile-status-style {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 25%;
  height: 25%;
  border-radius: 50%;
}

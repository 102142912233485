.marker-wrap{
  position: absolute;
  width: 76px;
  height: 76px;
  left: -38px;
  top: -38px;
}
.marker-point-wrap{
  position: absolute;
  left: 26px;
  top: 27px;
}
.marker-wrap .image-style {
  position: absolute;
}
.broadcast-wrap{
  position: absolute;
  width: 76px;
  height: 76px;
  animation: fadeIn 3s infinite;
  -webkit-animation: fadeIn 3s infinite;
  -moz-animation: fadeIn 3s infinite;
  -o-animation: fadeIn 3s infinite;
  -ms-animation: fadeIn 3s infinite;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
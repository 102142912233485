.missed-checkin-procedure-header {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 24px;
}

.missed-checkin-procedure-wrap{
  padding: 5px;
}

@media (max-width: 1440px) {
  .missed-checkin-procedure-wrap .watch-me-bullet-list-item-wrap .video-call .rounded-button-wrap  {
    background: #02A57D !important;
  }

  .missed-checkin-procedure-wrap .watch-me-bullet-list-item-wrap .mobile-call .rounded-button-wrap  {
    background: #02A57D !important;
  }
  .missed-checkin-procedure-wrap .watch-me-bullet-list-item-wrap .emergency-contact .rounded-button-wrap  {
    background: #1E1E1E !important;
  }
}
.contact-marker-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-marker-wrap-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: -56px;
}

.contact-marker-wrap-container .marker-point {
  position: relative;
  width: 26px;
  height: 26px;
}

.contact-marker-wrap-container .contact-marker-wrap {
  display: flex;
  padding: 3px;
  gap: 5px;
  position: relative;
  background: #FFFFFF;
  border-radius: 80px;
  z-index: 10;
  align-items: center;
}

.contact-marker-wrap-container .contact-marker-wrap .contact-info-container {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding-top: 3px;
  padding-right: 10px;
  justify-content: space-around;
}

.contact-marker-wrap-container .contact-marker-wrap .contact-info-container .distance-time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-top: 7px;
}

.contact-marker-wrap-container .contact-marker-wrap .contact-info-container .distance-time .time-of-last {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contact-marker-wrap-container .pointer {
  position: absolute;
  z-index: 9;
  width: 14.39px;
  height: 14.39px;
  bottom: -5px;
  background: #FFFFFF;
  border-radius: 2px;
  transform: rotate(45deg);
}

.contact-marker-container .marker-pointer {
  width: 26px;
  height: 26px;
}

.contact-marker-container .marker-pointer .marker-wrap {
  left: 1px;
  top: 0px;
}

.contact-marker-container .marker-pointer .marker-wrap .marker-point-wrap{
  left: 0px;
  top: 0px;
}

.contact-marker-container .marker-pointer .marker-wrap .broadcast-wrap{
  left: -26px;
  top: -27px;
}
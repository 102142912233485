.battery-styles {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;
  width: 72px;
  height: 28px;
  background: rgba(30, 30, 30, 0.5);
  backdrop-filter: blur(50px);
  border-radius: 8px;
  position: absolute;
  top: 10px;
  left: calc(50% - 36px);
}
.battery-background {
  background: #DC3545;
}
.battery-background .battery-icon .battery-body {
  justify-content: center !important;
}
.battery-styles .battery-icon {
  display: flex;
  gap: 2px;
  align-items: center;
}
.battery-styles .battery-icon .battery-head {
  height: 5px;
  width: 2px;
  background: #FFFFFF;
  border-radius: 4px;
}

.battery-styles .battery-icon .battery-body {
  width: 17px;
  height: 12px;
  border: solid 2px #FFFFFF;
  border-radius: 3px;
  padding: 1px;
  display: flex;
  gap: 1px;
  justify-content: flex-start;
  align-content: center;
}

.battery-styles .battery-icon .battery-body .first,
.battery-styles .battery-icon .battery-body .second,
.battery-styles .battery-icon .battery-body .three,
.battery-styles .battery-icon .battery-body .four {
  width: 2px;
  background: #FFFFFF;
  border-radius: 2px;
  height: auto;
}
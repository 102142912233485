.medical-info-style {
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 8px 0px;
}

.medical-info-style .medical-info {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 16px;
  border-bottom: 1px solid #D6D8D9;
}

.medical-info-style .emergency-medical {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
  margin: 0px 16px;
  border-bottom: 1px solid #D6D8D9;
}

.medical-info-style .emergency-medications {
  border-bottom: 1px solid #D6D8D9;
  margin: 0px 16px;
}

.medical-info-style .emergency-notes {
  margin: 0px 16px;
}

.medical-info-style .checkbox-field-style, .medical-info-style .input-label-style {
  flex: 0 0 33%;
}

.watch-me-bullet-list-item-wrap {
  flex-direction: row;
  display: flex;
  padding: 10px 0px;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  gap: 8px;
}

.watch-me-bullet-list-item-wrap .left-item-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.watch-me-bullet-list-item-name {
  flex-direction: row;
  display: flex;
}

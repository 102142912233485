.button-style-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-radius: 3px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: 0.5s;
}

.button-style-primary:active {
  transition-duration: 0.25s;
  opacity: 0.2
}

.disabled {
  opacity: 0.25;
  pointer-events: none;
}

.button-style-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #007fa1;
  box-sizing: border-box;
  border-radius: 3px;
  color: #007fa1;
  background-color: #ffffff;
  padding: 8px 20px;
}

.button-style-cancel:active {
  transition-duration: 0.25s;
  opacity: 0.2
}

.button-style-image-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-radius: 3px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  padding: 15px 20px;
}

/* .button-style-image-text:active {
  transition-duration: 0.25s;
  opacity: 0.2;
} */

.button-style-image-text img {
  margin-right: 8px !important;
}

.button-style-only-image {
  width: auto;
  height: auto;
  cursor: pointer;
}

.button-style-only-image:active {
  transition-duration: 0.25s;
  opacity: 0.2;
}

.button-style-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 3px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.button-style-secondary:active {
  transition-duration: 0.25s;
  opacity: 0.2;
}

.button-style-secondary img {
  margin-right: 8px !important;
}

.table-row-button-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 3px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 10px;
  cursor: pointer;
  letter-spacing: 0.5px;
  text-align: center;
}

.table-row-button-style:active {
  transition-duration: 0.25s;
  opacity: 0.2;
}

.button-style-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border: solid 1px #d6d8d9;
  border-radius: 3px;
  background-color: white;
  color: #000000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

.button-style-profile:active {
  transition-duration: 0.25s;
  opacity: 0.2;
}

.icon-button-style {
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  flex-direction: column;
}

.icon-button-wrap-style {
  color: rgb(255, 255, 255);
  width: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

.icon-button-style-text {
  margin-bottom: 5px;
}

.icon-button-wrap-disabled-style {
  opacity: 0.4;
  pointer-events: none;
}

.rounded-button-wrap {
  border-radius: 50px;
  padding: 8px 12px;
  height: auto;
  width: fit-content;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-family: 'Inter';
  transition-duration: 0.4s;
  gap: 10px;
}

.rounded-button-wrap:active {
  transition-duration: 0.25s;
  opacity: 0.2;
}

.square-button-wrap {
  background: #ffffff;
  border: 1px solid #d6d8d9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 8px 8px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.square-button-wrap:active {
  transition-duration: 0.25s;
  opacity: 0.2;
}

.button-image-round-style {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6ddcc;
  border-radius: 16px;
  text-align: center;
  font-size: 12px;
  font-family: 'Inter';
  margin: 13px auto 16px;
  font-weight: 400;
  cursor: pointer;
  width: '61px';
  height: '22px';
  transition: 0.5s;
}

.button-image-round-style:active {
  transition-duration: 0.25s;
  opacity: 0.2;
}

.button-image-round-style svg {
  margin-right: 5px;
}
.table-style thead tr{
    background: #F5F7F7;
    height: 40px;

}

.table-style tr th {
    color: #2C2E2E;
    border: 1px solid #D6D8D9;
    border-right: 0;
    border-left: 0;
}

.table-style tr th:last-child, .table-style tr th:first-child {
    border-right: 1px solid #D6D8D9;
    border-left: 1px solid #D6D8D9;
}

.table-header {
    background: #F5F7F7;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px;
    border: 1px solid #D6D8D9;
    border-radius: 8px 8px 3px 3px;
}

.table-header .header-title {
    color: #2C2E2E;
    font-size: 14px;
    font-family: 'Inter';
    padding-right: 10px;
}

.table-header .header-title.call-op .table-row-button-style {
    margin-right: 16px;
}

.table-toggle {
    background: #F5F7F7;
    border-radius: 3px;
    display: flex;
    height: 40px;
    padding: 8px 8px 8px 12px;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 5px;
}

.table-content {
    max-height: calc(100vh - 426px);
    overflow-y: auto;
}

.table-content::-webkit-scrollbar {
    width: 0px;
    background-color: #F5F5F5;
  }

.table-home-content.active .table-row-content {
    background: #F2F9FA !important;
    border: 1px solid #0DA9B9 !important;
    color: #000000 !important;
}

.home-table .data-toggle{
    font-size: 12px;
    margin-bottom: 8px;
  }
  
  .home-table .data-toggle span{
    float: left;
    margin-right: 8px;
  }
  
  .home-table .data-toggle .checkbox-style{
    width: 18px;
    height: 18px;
  }

  .open-calls-table{
    margin-bottom: 40px;
  }
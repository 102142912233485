.acw-popup {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.acw-popup .acw-content {
  width: 460px;
  height: 376px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  background: #FFFFFF;
  box-shadow: 0px 20px 24px -4px rgb(16 24 40 / 8%), 0px 8px 8px -4px rgb(16 24 40 / 3%);
  border-radius: 4px;
}

.acw-popup .acw-content .acw-content-right {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 328px;
  justify-content: space-between;
}

.acw-popup .acw-content .acw-content-right .acw-content-call-status .group-btn-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.acw-popup .acw-content .acw-content-right .acw-content-call-status .group-btn-status .empty-circle {
  background: #FFFFFF;
  border-radius: 100%;
  height: 21px;
  width: 21px;
  margin-right: 10px;
}

.acw-popup .acw-content .acw-content-right .acw-content-call-status .group-btn-status .empty-circle.open {
  border: 2px solid #02A57D;
}

.acw-popup .acw-content .acw-content-right .acw-content-call-status .group-btn-status .empty-circle.open-active {
  border: 4px solid #02A57D;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.acw-popup .acw-content .acw-content-right .acw-content-call-status .group-btn-status .empty-circle.open-active div {
  background: #02A57D;
  border-radius: 100%;
  height: 9px;
  width: 9px;
}

.acw-popup .acw-content .acw-content-right .acw-content-call-status .group-btn-status .empty-circle.close {
  border: 2px solid #DC3545;
}

.acw-popup .acw-content .acw-content-right .acw-content-call-status .group-btn-status .empty-circle.close-active {
  border: 4px solid #DC3545;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.acw-popup .acw-content .acw-content-right .acw-content-call-status .group-btn-status .empty-circle.close-active div {
  background: #DC3545;
  border-radius: 100%;
  height: 9px;
  width: 9px;
}

.acw-popup .acw-content .acw-content-right .acw-content-submit-btn .rounded-button-wrap {
  border-radius: 0px;
}

.active-border {
  padding: 2px;
  border: solid 2px #0DA9B9;
  border-radius: 6px;;
}
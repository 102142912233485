.watchme-info-control-wrap {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 16px;
  padding: 10px 16px;
  height: 60px;
  margin-bottom: 1px;
  width: 100%;
}

.watchme-info-control-wrap .watch-me-info-control-wrap {
  display: flex;
  flex-direction: row;
}

.watchme-info-control-wrap .watch-me-info-control-wrap .watch-me-info-control-left-column {
  width: 50%;
}
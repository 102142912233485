.map-stats {
    position: absolute;
    transform: translate(-50%, 10px);
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 300px;
    bottom: 25px;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    vertical-align: middle;
}

.map-stats img {
    vertical-align: middle;
    display: inline-block;
}

.map-stats span {
    margin-left: 13px;
    vertical-align: middle;
}

.map-stats p {
    display: inline !important;
}

.map-stats img {
    margin-right: 10px;
}

.map-control {
    position: relative;
    height: inherit;
}

.map-container .map-container-detail {
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 0px;
    left: 0;
    border-top: 1px solid #ccc;
    transition: all 0.5s ease-in-out;
}

.map-container .map-container-detail.full-screen {
    height: 100%;
    transition: all 0.5s ease-in-out;
}

.map-container .map-container-detail .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    bottom: 125px !important;
    right: 28px !important;
}

.map-container .map-container-detail .gmnoprint div {
    width: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map-container .map-container-detail .gmnoprint div button[title='Zoom in'], button[title='Zoom out'] {
    width: 28px !important;
    height: 28px !important;
    ;
}

.map-stats .image-style {
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    position: static;
    height: 24px !important;
    left: 0px;
    top: 0px;
    border-radius: 8px !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 5px;
}

.image-text-style {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map-custom .tracking-tool {
    bottom: 8px;
    position: absolute;
}

.map-custom .tracking-tool.details {
    left: 35%;
}

.map-custom .tracking-tool.contacts .tracking-style {
    margin-left: 8px;
}

.map-custom .tracking-tool.contacts .tracking-style .button-container .button-style-primary {
    padding: 0;
}

.map-custom .tracking-tool.contacts .tracking-style .button-container .button-style-primary .tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.street-view-button {
    display: flex;
    border: 0px;
    right: 6px;
    position: absolute;
    cursor: pointer;
    border-radius: 2px;
    height: 28px;
    width: 28px;
    bottom: 135px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 9;
}

.custom-full-map {
    display: flex;
    border: 0px;
    right: 6px;
    position: absolute;
    cursor: pointer;
    border-radius: 2px;
    height: 28px;
    width: 28px;
    bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 9;
}

.map-zoom-button {
    display: flex;
    border: 0px;
    right: 6px;
    position: absolute;
    cursor: pointer;
    border-radius: 2px;
    height: 28px;
    width: 28px;
    bottom: 50px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 20%);
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 9;
}

.map-street-view .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom .gmnoprint div {
    user-select: none;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px !important;
    border-radius: 2px !important;
    cursor: pointer;
    background-color: rgb(255, 255, 255) !important;
    color: #000 !important;
    width: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map-street-view .gm-control-active>img:nth-child(1) {
    display: none;
}

.map-street-view .gm-control-active>img:nth-child(4) {
    display: block;
}

.map-street-view .gm-control-active:disabled>img:nth-child(1) {
    display: block;
}

.map-street-view .gm-control-active:disabled>img:nth-child(4) {
    display: none;
}
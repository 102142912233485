.watch-me-timer-session-wrap {
  background-color: #2C2E2E;
  width: 100%;
  height: inherit;
  position: relative;
  padding: 24px;
}

.watch-me-timer-session-header {
  text-align: center;
  background-color: #2C2E2E;
  padding-bottom: 24px;
}

.watch-me-timer-session-body {
  overflow-y: auto;
  max-height: calc(100% - 187px);
}

.watch-me-timer-session-body.session-body-no-completed {
  max-height: calc(100% - 100px);
  overflow-y: auto;
}

.watch-me-timer-session-body.session-body-has-detail {
  max-height: calc(100% - 460px);
  overflow-y: auto;
}

.watch-me-timer-session-details {
  transition: all 0.5s;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2C2E2E;
  padding: 24px;
  max-height: 410px;
}

.watch-me-timer-session-details.collapsed {
  height: 0;
  bottom: -50px;
  position: fixed;
}

.watch-me-timer-session-details-options-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
}

.watch-me-timer-session-details-center-align {
  text-align: center;
}
.caller-detail-style {
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 8px 0px;
}

.caller-detail-style .caller-detail-phone {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 16px;
  border-bottom: 1px solid #D6D8D9;
}

.caller-detail-style .caller-detail-email {
  margin: 0px 16px;
}

.caller-detail-style .caller-detail-address {
  margin: 0px 16px;
  border-bottom: 1px solid #D6D8D9;
}
.street-view-dialog-style {
  position: absolute;
  left:30%;
  top: 100px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 20%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 120px;
  padding: 24px;
}

.street-view-dialog-style .close {
  position: absolute;
  right: 24px;
}
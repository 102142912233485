.mobile-call-info-user-info {
  width: 90%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 5px;
  text-align: center;
}

.mobile-call-info-user-info .date-time-duration {
  display: flex;
  gap: 20px;
}

.mobile-call-info-user-info .date-time-duration .date-time {
  display: flex;
  gap: 20px;
}
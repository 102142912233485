.table-row-content {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px 8px 15px;
  border: 1px solid #D6D8D9;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 2px;
}

.table-row-content:hover {
  background: #F2F9FA !important;
  border: 1px solid #0DA9B9 !important;
}

.table-row-content .header-title {
  padding-right: 10px;
}

.table-row-content .header-title.type {
  width: 5%;
  display: flex;
}

.table-row-content .header-title.type .text-style {
  width: 45px;
  position: relative;
  top: 2px;
}

.table-row-content .header-title.from {
  width: 15%;
}

.table-row-content .header-title.contact {
  width: 14%;
}

.table-row-content .header-title.location {
  width: 35%;
}

.table-row-content .header-title.received {
  width: 16%;
}

.table-row-content .header-title.duration {
  flex-grow: 1;
}

.table-row-content .header-title.call-op {
  width: 8%;
}

.table-row-content .header-title.call-op .table-row-button-style {
  margin-right: 10px;
  padding: 0 5px;
}

.table-row-content .call-op {
  border-radius: 3px;
  padding: 5px 8px;
  min-width: 53px;
  height: 24px;
  font-weight: 700;
  font-size: 11px;
  text-align: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
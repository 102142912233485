.video-control-wrap {
  background: rgba(255, 255, 255, 0.16);
  border-radius: 16px;
  padding: 10px 16px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.video-control-wrap .watch-me-timer-session-column-button-wrap {
  justify-content: flex-end;
  text-align: right;
  align-items: center;
  display: flex;
}
.start-profile-style {
  display: flex;
  width: 303px;
  height: 367px;
  flex-direction: column;
  align-items: center;
  padding: 24px 40px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: default;
}

.start-profile-separator-style {
  height: 1px;
  background-color: #D6D8D9;
  width: 100%;
  margin: 20px 0px;
}

.start-profile-status-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

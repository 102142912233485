.outer-wrapper{
  height: 46px;
  position: absolute;
}
.outer-wrapper.selected{
  width: 198px;
}
.outer-wrapper.not-selected{
  width: 130px;
}
.popup-wrap {
  display: flex;
  padding: 2px 16px 2px 2px;
  position: absolute;
  background: #FFFFFF;
  border-radius: 80px;
  align-items: center;
  /* text-align: center; */
}
.popup-wrap.selected{
  left: -104px;
  top: -65px;
}
.popup-wrap.not-selected{
  left: -62px;
  top: -65px;
}
.popup-pointer{
  position: absolute;
  width: 14.39px;
  height: 14.39px;
  left: calc(50% - 7.19px);
  bottom: -7.19px;
  background: #FFFFFF;
  border-radius: 2px;
  transform: rotate(45deg);
}
.text-wrap{
  display: flex;
  line-height: 10px;
}
.time-text-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.time-text-value {
  margin-top: -1px;
}
.search-result-wrap {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.search-result-wrap .input-labelled-wrap {
  background: #FFFFFF;
  border: 1px solid #9DD9E0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.search-results-wrap{
  margin-top: 24px;
  width: 100%;
}

.search-spinner-wrap{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.search-table-container .search-table-content {
  max-height: calc(100vh - 500px);
  overflow-y: auto;
}

.search-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 400;
  width: 100%;
  margin-top: 2px;
  font-size: 14px;
}

.record-deleted{
  border: 1px solid red;
}

.search-table-row:hover {
  border: 1px solid #007FA1;
  background: #E7F6F8;
}

.search-table-row .search-table-column {
  position: relative;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
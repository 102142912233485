.dropdown-select-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #D6D8D9;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #FFFFFF
}

.dropdown-select-style.active {
  background: #FFFFFF;
  border: 2px solid #0DA9B9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-color: #0DA9B9 !important;
}

.dropdown-select-title {
  margin-right: 8px;
  font-size: 15px;
  color: #000000
}

ul {
  margin: 0;
  padding: 0;
  text-align: center;
}

.select-options {
  position: absolute;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #2C2E2E;
  top: 110%;
  left: 0;
  z-index: 99;
}
.select-options.active {
  border: 2px solid #0DA9B9;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 4px 4px;
}

.select-options li {
  list-style-type: none;
  padding: 6px 12px;
  cursor: pointer;
}

.select-options li:hover {
  background: #E7F6F8;
}

.select-options-default {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropdown-select-style .dropdown-img {
  top: 2px;
  position: relative;
  color: #9DD9E0;
}

.star {
  color: #DC3545;
}

.check-icon {
  right: 18px;
  position: absolute;
}

.select-options-default .select-placeholder{
  display: flex;
}

.select-options-default .select-placeholder.selected {
  position: absolute;
  top: 1px;
  left: 28px;
  text-transform: uppercase;
}
.no-response-procedure-items {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.no-response-procedure-items.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.no-response-procedure-header {
  justify-content: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  height: 36px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 16px;
}
.no-response-procedure-header.active {
  background: #1E1E1E;
}
.caller-overview-style {
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.caller-overview {
  margin: 5px 16px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
* {
  box-sizing: border-box;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
  z-index: 10;
  background: #fff;
  height: inherit;
  width: 100%;
  box-shadow: 0 0 15px #d5d5d5;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
}

header .header-logo {
  position: relative;
}

.header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.header-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding: 5px 6px 5px 13px;
  margin: 0px 0px;
  cursor: pointer;
}

.header-profile.active {
  background: #E7F6F8;
  box-sizing: border-box;
}

.header-profile span {
  margin: 0px 5px;
}

.header-right .start-profile {
  position: absolute;
  right: 20px;
  top: 100%;
}

.header-right .grid-icon {
  width: 42px;
  height: 42px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.header-right .grid-icon.active {
  border: 1px solid #0DA9B9;
  box-sizing: border-box;
  border-radius: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #E7F6F8;
}

.header-right .search-icon {
  width: 42px;
  height: 42px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.header-right .close-search-icon {
  background: #E7F6F8;
  border: 1px solid #0DA9B9;
  box-sizing: border-box;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
}

.search-result-container {
  position: absolute;
  right: 0;
  top: 56px;
  z-index: 3;
  background: #FFFFFF;
  padding: 16px 40px 40px;
  width: 50%;
  height: calc(100vh - 120px);
  border: 1px solid #D6D8D9;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.2);
  -webkit-animation-name: showResult;
  -webkit-animation-duration: .3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
}

.hide-search-result {
  position: absolute;
  right: -100px;
  top: 56px;
  z-index: 3;
  background: #FFFFFF;
  padding: 16px 40px 40px;
  width: 50%;
  height: calc(100vh - 120px);
  border: 1px solid #D6D8D9;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.2);
  animation: hideResult;
  -webkit-animation-duration: .5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
}

@keyframes hideResult {
  from {
    right: 0;
  }
  to {
    right: -50%;
  }
}

@keyframes showResult {
  from {
    right: -50%;
  }
  to {
    right: 0;
  }
}

.header-right .header-menu-dock {
  position: absolute;
  top: 100%;
  right: 130px;
}

.header-active-icon {
  margin: 0px;
  padding: 0px;
  position: relative;
  top: -28px;
  right: -17px;
}

.version {
  position: absolute;
  padding: 2px 0px 0px 16px;
  color: #8f8f8f;
}

.header-right-icon-wrap{
  margin-right: 15px;
}

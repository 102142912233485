.tab-list {
  padding-left: 0;
}

.tab-list-item {
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: left;
  margin: 0;
  color: #000;
  padding: 15px;
  border-radius: 3px;
  border-bottom: 4px solid transparent;
  position: relative;
}

.tab-list-active {
  border-bottom: 4px solid #0DA9B9;
  transition: 0.5s ease;
  width: auto;
}
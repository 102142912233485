.table-header-style {
  background: #F5F7F7;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #D6D8D9;
  border-radius: 8px 8px 3px 3px;
}

.table-header-style .header-title {
  color: #2C2E2E;
  font-size: 14px;
  font-family: 'Inter';
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
.chat-options-style {
  display: flex;
  gap: 12px;
}

.btn-chat-option {
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  padding: 9px;
  text-align: center;
  cursor: pointer;
}

.btn-chat-option:hover {
  background: #D6D8D9;
}

.btn-chat-option:hover .text-style {
  color: #000000 !important;
}

.btn-chat-option.location .text-style {
  margin-left: -10px !important;
}

.btn-chat-option.active-hand-waving {
  border: 1px solid #0DA9B9;
  background: #E7F6F8;
}

.btn-chat-option.active-hand-waving svg path {
  stroke: #0DA9B9;
}

.btn-chat-option.active-pause {
  border: 1px solid #0DA9B9;
  background: #E7F6F8;
}

.btn-chat-option.active-pause svg path {
  stroke: #0DA9B9;
}

.btn-chat-option.active-first-aid {
  border: 1px solid #0DA9B9;
  background: #E7F6F8;
}

.btn-chat-option.active-first-aid svg path {
  stroke: #0DA9B9;
}

.btn-chat-option.active-anything-else {
  border: 1px solid #0DA9B9;
  background: #E7F6F8;
}

.btn-chat-option.active-anything-else svg path {
  stroke: #0DA9B9;
}

.btn-chat-option.active-chat-end {
  border: 1px solid #0DA9B9;
  background: #E7F6F8;
}

.btn-chat-option.active-chat-end svg path {
  stroke: #0DA9B9;
}

.btn-chat-option.active-siren {
  border: 1px solid #0DA9B9;
  background: #E7F6F8;
}

.btn-chat-option.active-siren svg path {
  stroke: #0DA9B9;
}

.btn-chat-option.active-location {
  border: 1px solid #0DA9B9;
  background: #E7F6F8;
}

.btn-chat-option.active-location svg path {
  stroke: #0DA9B9;
}

.btn-chat-option.btn-end{
  background: #DC3545;
}

@media (max-width: 1440px) {
  .chat-options-style {
    gap: 7px;
  }
  .btn-chat-option.anything {
    display: none;
  }
}

.ripple {
  margin: auto;
  background-color: #fff;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: grid;
  animation: ripple 3s linear infinite;
  background-color: #9dd9e0;
}

.ripple::before,
.ripple::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  animation: inherit;
  animation-delay: 1s;
  width: 96px;
  height: 96px;

}

.ripple::after {
  animation-delay: 2s;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0.7rem #9dd9e0;
  }

  100% {
    box-shadow: 0 0 0 8rem rgba(255, 255, 255, 0);
  }
}

.mobile-call-info-view-wrap {
  width: 460px;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 1440px) {
  .mobile-call-info-view-wrap {
    width: 370px;
  }
}

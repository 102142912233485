.complete-procedure-options-wrap {
  background-color: #1E1E1E;
  height: 136px;
  width: inherit;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 24px;
}

.complete-procedure-options-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
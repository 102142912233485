.input-style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 3px;
  padding: 0 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-style: solid;
  border-width: 0.5px;
  width: 99%;
}

input:focus-visible {
  outline: 1px solid #007bad;
}

.input-filled {
  padding-top: 10px;
  transition: 0.2s ease all;
}

.input-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  height: inherit;
}

.input-container .filled {
  position: absolute;
  top: 5px;
  pointer-events: none;
  left: 15px;
  transition: 0.2s ease all;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}

.input-container .input-style::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: #626566;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 10px;
  line-height: 1;
  left: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

.input-container input {
  border-radius: 4px;
  border: none;
  font-size: 15px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input-labelled-wrap {
  display: flex;
  border-style: solid;
  border-width: 0.5px;
  border-radius: 3px;
  align-items: center;
}

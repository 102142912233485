@font-face {
  font-family: "Inter";   /*Can be any text*/
  src: local("Inter"),
    url("../assets/fonts/Inter.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: 'Inter';
}

.scroll-custom::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(235, 214, 214, 0.3);
  border-radius: 8px;
  background-color: #F5F5F5;
}

.scroll-custom::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #F5F5F5;
}

.scroll-custom::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(235, 214, 214, 0.3);
  background-color: #ABB1B2;
}

.disabled {
  opacity: 0.75;
  pointer-events: none;
}
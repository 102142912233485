.video-call-wrap {
  height: inherit;
  width: inherit;
  position: relative;
}

.publisher-container {
  position: absolute;
  border-radius: 12px;
  background-color: rgb(0, 0, 0);
  top: 0;
  left: 0;
  z-index: 10;
  margin: 16px;
  height: 160px;
  width: 120px;
}

.video-call-container {
  height: 100%;
  width: 100%;
}

.subscriber-container {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.subscriber-container .loader {
  margin-top: 5px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  top: 40%;
  left: 40%;
  z-index: 100;
}

.subscriber-container .loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
  z-index: 100;
}

.publisher-container .OT_publisher {
  display: block !important;
  border-radius: 12px;
}

.subscriber-container .OT_subscriber {
  display: block !important;
}

.OT_publisher, .OT_subscriber {
  display: none;
}

.merged-call2-wrap {
  position: absolute;
  width: 120px;
  height: 160px;
  border-radius: 12px;
  background-color: rgb(0, 0, 0);
  top: 0%;
  right: 0px;
  margin: 16px;
}

.merged-call2-wrap .OT_publisher {
  display: block !important;
  border-radius: 12px;
}

.video-call-wrap .mobile-call-info-user-info {
  position: absolute;
  margin: 16px;
  bottom: 114px;
  z-index: 30;
}

.video-call-wrap .change-height {
  bottom: 166px;
}

.video-call-wrap .call-control-wrap {
  position: absolute;
  bottom: 0px;
  z-index: 2;
}

.mobile-call-no-video-info {
  width: auto;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 5px;
  background: #2C2E2E;
}

.mobile-call-no-video-info .loader {
  margin-top: 5px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.mobile-call-no-video-info .loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
}

.mobile-call-hung-up-info {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 5px;
  background: #2C2E2E;
  z-index: 9;
  left: 0;
  top: 0;
}

.hung-up-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hung-up-options-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin-left: 15px;
  flex-direction: column;
  margin: 16px;
}

.video-additional-call-end-button {
  position: relative;
  left: 35px;
  top: -55px;
}

.video-additional-call-view {
  width: 120px;
  height: 160px;
}

.video-connecting-panel {
  background: #2C2E2E;
  width: auto;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.video-connecting-panel .loader {
  margin-top: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.video-connecting-panel .loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
}

.camera-turn-off {
  position: absolute;
  z-index: 11;
  background: #2C2E2E;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 226px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera-turn-off .circle-blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
  position: absolute;
}

.camera-turn-off .circle-blink .circle-image-style {
  height: auto !important;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}
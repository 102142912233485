.note-header {
  display: flex;
  flex-direction: row;
  padding: 8px 8px 8px 12px;
  justify-content: space-between;
  height: 56px;
  background: #F5F7F7;
  border-radius: 4px;
  flex-grow: 0;
  margin: 4px 0px;
  align-items: flex-start
}

.note-header .note-header-right {
  display: flex;
  margin-top: 10px
}

.note-header .note-header-right .text-style {
  margin-right: 10px !important;
}

.note-header .note-header-left {
  display: flex;
  margin-top: 10px
}

.note-header .note-header-left img {
  margin-right: 8px;
  cursor: pointer;
}
.checkbox-style {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.checkbox-text-style {
  color: white;
  font-size: 20px;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

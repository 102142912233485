.note-controls {
  width: 100%;
  margin-bottom: 16px;
}

.note-controls .btn-grs-submit {
  display: flex;
}

.note-controls .btn-grs-submit .button-style-image-text{
  margin-right: 12px;
}

textarea:read-only {
  opacity: 0.7;
  border: 1px solid transparent;
  /* overflow: hidden; */
}

textarea:read-only:focus-visible {
  outline: none;
}

textarea {
  border-radius: 4px;
  border: 1px solid #D6D8D9;
  margin-top: 0px;
  resize: none;
  width: -webkit-fill-available;
  background-color: #FFFFFF;
  color: #626566;
  padding: 6px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 15px;
}

textarea:focus-visible {
  outline: #007FA1;
  border-radius: 4px;
}

textarea:read-only::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(235, 214, 214, 0.3);
  border-radius: 4px;
  background-color: #F5F5F5;
}

textarea:read-only::-webkit-scrollbar {
  width: 2px;
  background-color: #F5F5F5;
}

textarea:read-only::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(235, 214, 214, 0.3);
  background-color: #555;
}

.call-control-wrap{
  width: 100%;
  background: #777777;
}
.mobile-call-top-button-wrap{
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 56px;
}
.mobile-call-bottom-button-wrap{
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 106px;
}
.mobile-call-top-button-wrap .button-style-image-text {
  border-radius: 999px;
}
#mobile-call-end-button{
  display: flex;
}
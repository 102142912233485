.tracking-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  height: 36px;
  background: rgba(30, 30, 30, 0.7);
  border-radius: 4px;
}

.button-container {
  border: 1px solid transparent;
  border-radius: 4px;
}

.button-container:hover {
  background: rgba(255, 255, 255, 0.16);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}

.button-container.last-known-active,
.button-container.locate-active,
.button-container.tracking-active,
.button-container.locate-active {
  background: #000000;
  box-sizing: border-box;
  border-radius: 4px;
}

.tracking-style .button-container .button-style-primary {
  padding-right: 5px;
  font-size: 15px;
}

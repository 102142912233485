.header-navigation {
  display: flex;
  flex-wrap: wrap;
  left: 75px;
  position: relative;
  align-items: center;
  align-content: center;
  height: inherit;
}

.header-navigation .link {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: left;
  margin: 0;
  font-weight: 600;
  color: #000;
  padding: 23px 15px;
  border-radius: 3px;
  border-bottom: 4px solid transparent;
  position: relative;
  top: 1px;
  height: inherit;
}

.link .active {
  border-bottom: 4px solid #0DA9B9;
  transition: 0.5s ease;
  width: auto;
}

.homeIconMenu {
  background: url(../../../assets/images/home-icon.svg) no-repeat 0 0;
}

.navlink-disable{
  pointer-events: none;
  opacity: 0.4;
}



@media (max-width: 375px) {}

@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 1200px) and (max-width: 1349px) {}
.bottom-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.bottom-left .bottom-left-col .text-style {
  margin-bottom: 5px !important;
}

.bottom-left-col:first-child {
  width: 15%;
}

.bottom-left-col:nth-child(2) {
  width: 10%;
}

.bottom-left-col:nth-child(3) {
  width: 35%;
}

.bottom-left-col:nth-child(4) {
  width: auto;
	min-height: 50px;
}

.background-lat-long {
  background: #000 !important;
}

.background-w3w {
  background: #DD1F1F !important;
}

.button-caller-location {
  display: none;
}

.w3w-show .lat-long-result{
	display: none;
}
.w3w-show .w3w-result{
	display: block;
}
.w3w-result{
	display: none;
}

.w3w-show .lat-long{
	background: #000;
}
.w3w-show .w3w{
	background: #DD1F1F;
}
.lat-long{
	background: #007FA1;
	border-radius: 3px 0 0 3px;
	padding:3px 5px;
	font-size:9px;
	color:#fff;
	font-weight:600; 
	cursor: pointer;
  margin-left: 2px;
}
.w3w{
	background: #000;
	border-radius: 0 3px 3px 0;
	padding:3px 5px;
	font-size:9px;
	color:#fff;
	font-weight:600; 
	cursor: pointer;
}

.spinner-wrap {
	display: flex;
	justify-content: center;
}
.menu-dock-style {
  width: 248px;
  height: 248px;
  padding: 24px 40px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 20%);
  border-radius: 4px;
  display: flex;
  align-items: center;
}



.menu-dock-style .menu-dock {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 40px;
  justify-items: center;
  text-align: center;
}

.menu-dock-style .menu-dock .dock-item {
  cursor: pointer;
  border: 1px solid transparent;
}

.menu-dock-style .menu-dock .dock-item:hover {
  border-color: #0DA9B9;
  background: #E7F6F8;
}
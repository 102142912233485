.VideoPlayer-style {
  object-fit: cover;
}

.info-sub-call{
  position: absolute;
  bottom: 100px;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info-sub-call .info-sub-call-time{
  display: flex;
  gap: 20px;
}
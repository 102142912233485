.contact-information-style {
  width: inherit;
  background: #ffffff;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.2);
  position: relative;
}

.contact-information-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: helvetica;
}

.contact-informaion-middle-style {
  position: absolute;
  width: 100%;
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-information-top-style {
  height: 64px;
  background: linear-gradient(254.12deg, #0da9b9 0%, #007fa1 100%);
}
.contact-information-image-style {
  position: absolute;
  left: calc(50% - 24px/2 + 157px);
  top: 20px;
}
.contact-information-profile-image-style {
  position: absolute;
  width: 96px;
  height: 96px;
  left: calc(50% - 96px/2);
  top: 16px;
}
.contact-information-text-style {
  width: 100%;
  height: 28px;
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.25px;
  color: #2c2e2e;
  margin: 40px 0px 10px;
  padding-top: 10px;
}
.contact-information-button-main-style {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  margin: 0 auto 20px;
}
.contact-information-button-style {
  margin: 0 5px;
}
.contact-information-detail-style{
  padding: 10px 12px;
  width: 100%;
}
.contact-information-ls-contact-numbers-style{
  margin-top:10px;
}
.contact-information-ls-contact-numbers-style .contact-information-panel-wrap-style{
  border-radius: 0;
  margin-top: 1px;
}
.contact-information-ls-contact-numbers-style .contact-information-panel-wrap-style:first-child{
  border-radius: 8px 8px 0 0;
}
.contact-information-ls-contact-numbers-style .contact-information-panel-wrap-style:last-child{
  border-radius: 0 0 8px 8px ;
}
.contact-information-profile-logo-icon{
  background: #9DD9E0;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 20%);
  border-radius: 8px;
  position: absolute;
  width: 26px;
  height: 26px;
  right: 0px;
  bottom: 5px;
  padding: 3px;
  z-index: 999;
}

.contact-info-btn .button-style-image-text{
  padding: 15px 6px !important;
}

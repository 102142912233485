.map-search{
	height: 52px;
	width: 390px;
	position: absolute;
	left: 8px;
	top: 8px;
	z-index: 2;
	display: flex;
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.map-search input {
	width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 0 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-style: solid;
  border-width: 0.5px;
	background: white;
	border: 0;
	outline: none;
	padding-left: 52px;
}

input:focus-visible {
  outline: 1px solid #007bad;
}

.search-field-icon{
	position: absolute;
	top: 15px;
	left: 15px
}



.conversation-style{
  width: 100%;
  position: relative;
  background: #FFFFFF;
  border-right: 1px solid #D6D8D9;
  padding: 0 16px 16px 16px;
  height: inherit;
}

.conversation-style .conversation-content {
  height: calc(100vh - 300px);
  padding-bottom: 24px;
  overflow-y: auto;
}

.conversation-style .recorded-chat {
  height: 100% !important;
}

.conversation-style .conversation-content::-webkit-scrollbar {
  width: 0px;
}

.conversation-style .conversation-content .chat-item {
  display: flex;
  margin-top: 16px;
}

.conversation-style .conversation-content .chat-end-message {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
}

.conversation-style .conversation-content .chat-item.operator {
  justify-content: flex-end;
  margin-left: 42px;
}
.conversation-style .conversation-content .chat-item.sub {
  justify-content: flex-start;
}

.conversation-style .conversation-content .chat-item .avatar-active {
  margin-right: 12px;
  position: relative;
}

.conversation-style .conversation-content .chat-item .avatar-active .status {
  position: absolute;
  top: 18px;
  right: 0px;
}

.conversation-style .conversation-content .call-ref {
  position: relative;
  text-align: center;
  margin: 24px 0 32px;
}

.message .message-header .message-header-time {
  display: flex;
  gap: 10px;
  justify-items: center;
  align-items: center;
}

.conversation-style .conversation-content .call-ref .line-chat{
  background: #D6D8D9;
  height: 1px;
  width: 100%;
}

.conversation-style .conversation-content .call-ref .text-style {
  position: relative;
  top: 10px;
  background: #fff;
  display: inline-block;
  z-index: 2;
  padding: 0 8px;
}

.conversation-style .conversation-content .chat-item .message .typing-message {
  width: 40px;
  height: 28px;
  background: #F5F7F7;
  border-radius: 0px 8px 8px 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
}

.conversation-style .conversation-content .chat-item .message .typing-message span {
  width: 4px;
  height: 4px;
  background: #626566;
  border-radius: 100%;
  margin: 2px;
}

.conversation-style .conversation-content .chat-item .message .typing-message span:first-child {
  animation: blink1 1s linear infinite;
}

.conversation-style .conversation-content .chat-item .message .typing-message span:nth-child(2) {
  animation: blink2 1s linear infinite;
}

.conversation-style .conversation-content .chat-item .message .typing-message span:nth-child(3) {
  animation: blink3 1s linear infinite;
}


.conversation-style .conversation-content .chat-item .message .message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.conversation-style .conversation-content .chat-item .message .message-content-operator {
  background: #1F99CF;
  border-radius: 8px 0px 8px 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 6px;
  padding: 14px;
}

.conversation-style .conversation-content .chat-item .message .message-content-sub {
  background: #F5F7F7;
  border-radius: 0px 8px 8px 8px;
  color: #2C2E2E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 6px;
  padding: 14px;
}

.conversation-style .conversation-footer {
  margin-top: 16px;
  max-height: 187px;
  position: absolute;
  bottom: 30px;
  border-top: 1px solid #D6D8D9;
  padding-top: 16px;
}

@keyframes blink1 {
  0% {
    opacity: .15;
    transform: translateY(-8px);
  }

  25% {
    opacity: .25;
    transform: translateY(-6px);
  }

  50% {
    opacity: .5;
    transform: translateY(-4px);
  }

  75% {
    opacity: .75;
    transform: translateY(-2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes blink2 {
  0% {
    opacity: .25;
    transform: translateY(-6px);
  }

  25% {
    opacity: .5;
    transform: translateY(-4px);
  }

  50% {
    opacity: .75;
    transform: translateY(-2px);
  }

  75% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: .15;
    transform: translateY(-8px);
  }
}

@keyframes blink3 {
  0% {
    opacity: .5;
    transform: translateY(-4px);
  }

  25% {
    opacity: .75;
    transform: translateY(-2px);
  }

  50% {
    opacity: 1;
    transform: translateY(0px);
  }

  75% {
    opacity: .15;
    transform: translateY(-8px);
  }

  100% {
    opacity: .25;
    transform: translateY(-6px);
  }
}

@media (max-width: 1440px) {
  .conversation-style .conversation-content {
    height: calc(100vh - 350px);
  }
}

.conversation-footer-style {
  height: inherit;
  position: relative;
  background: #FFFFFF;
}

.conversation-footer-style .conversation-action {
  display: flex;
}

.conversation-footer-style .conversation-input {
  position: relative;
  width: 100%;
  margin-bottom: 6px;
}

.conversation-footer-style .conversation-input .text-highlighted {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  padding: 8px;
  background: #E7F6F8;
  border-radius: 8px;
}

.conversation-footer-style .conversation-input .edit-message {
  position: absolute;
  top: 10px;
  left: 12px;
}

.conversation-footer-style .conversation-input .input-message {
  padding: 8px;
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
  color: #2C2E2E;
  height: 44px;
  transition: all .3s
}

.conversation-footer-style .conversation-input .input-message.has-value {
  height: 68px;
}

.conversation-footer-style .conversation-input .upload-file {
  position: absolute;
  top: 10px;
  right: 12px;
}

.conversation-footer-style .btn-send {
  margin-left: 8px;
}

.conversation-footer-style .conversation-input textarea {
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.conversation-footer-style .conversation-input textarea:focus {
  border: 2px solid #0DA9B9;
}

.conversation-footer-style .conversation-input textarea::-webkit-scrollbar {
  width: 0px;
  background-color: #F5F5F5;
}

@media (max-width: 1440px) {
  .conversation-footer-style .conversation-input .input-message {
    height: 92px;
  }
}
.time-text{
  margin-left: 10px !important;
}
.chat-spinner-wrap{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}
.message-content-text{
  white-space: pre-wrap;
  width: 100%;
}
.message-wrapper{
  display: flex;
  flex-direction: row;
}
.message-error{
  margin-right: 5px;
  padding-top: 22px;
}

.chat-image-warpper{
  padding-top: 10px;
  position: relative;
}

.downlod-image-loader{
  position: absolute;
  opacity: 0.6;
  display: none;
  top: 10px;
}
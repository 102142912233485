.dropdown-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #D6D8D9;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
  min-height: 48px;
}

.dropdown-style:hover {
  border: 1px solid #0DA9B9;
  background: #E7F6F8;

}

.dropdown-content-bottom-right {
  position: absolute;
  height: auto;
  width: auto;
  bottom: 83%;
  right: 0;
  z-index: 99;
}

.dropdown-content-bottom-caller-statistic {
  position: absolute;
  height: auto;
  width: auto;
  top: 48px;
  right: 0;
  z-index: 2;
}

.dropdown-content-right-active-agents {
  position: absolute;
  right: 0;
  bottom: 72px;
  top: 72px;
  z-index: 99;
}
.contact-method-style {
  width: 400px;
  height: 244px;
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #D6D8D9;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 20%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative
}

.contact-method-style .close {
  position: absolute;
  right: 24px;
}

.contact-method-style .profile {
  position: relative;
}

.contact-method-style .profile-icon {
  background: #9DD9E0;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 20%);
  border-radius: 8px;
  position: absolute;
  right: 10px;
  top: 31px;
}

.contact-method-style .btn-groups {
  display: flex;
  margin-top: 16px;
  gap: 8px;
}

.contact-method-style .btn-groups .square-button-wrap:hover {
  border:1px solid #9DD9E0;
}
.tooltip {
  position: relative;
}

.tooltip-content {
  position: absolute;
  background-color: #007FA1;
  text-align: center;
  color: #fff;
  transform: translateX(-50%);
  white-space: nowrap;
  border-radius: 3px;
  padding: 7px 8px;
  font-size: 11px;
  font-weight: 700;
  width: auto;
  z-index: 10;
}

.tooltip-content:before {
  content: "";
  position: absolute;
}

/* POSITION TOP */
.tooltip-content.top {
  bottom: 100%;
  left: 50%;
}

.tooltip-content.top:before {
  top: 100%;
  left: 50%;
  margin-left: -4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #007FA1;
}


/* POSITION RIGHT */
.tooltip-content.right {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 10px;
}

.tooltip-content.right:before {
  right: 100%;
  top: 50%;
  margin-top: -4px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #007FA1;
}

/* POSITION BOTTOM */
.tooltip-content.bottom {
  top: 100%;
  left: 50%;
  margin-bottom: 0;
  margin-top: 10px;
}

.tooltip-content.bottom:before {
  bottom: 100%;
  left: 50%;
  margin-left: -4px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #007FA1;
}

/* POSITION LEFT */
.tooltip-content.left {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 10px;
}

.tooltip-content.left:before {
  left: 100%;
  top: 50%;
  margin-top: -4px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #007FA1;
}

/* tooltip black */

.tooltip-custom .tooltip-content {
  background-color: #1E1E1E;
}

/* POSITION TOP */
.tooltip-custom .tooltip-content.top:before {
  border-top: 5px solid #1E1E1E;
}


/* POSITION RIGHT */

.tooltip-custom .tooltip-content.right:before {
  border-right: 5px solid #1E1E1E;
}

/* POSITION BOTTOM */
.tooltip-custom .tooltip-content.bottom:before {
  border-bottom: 5px solid #1E1E1E;
}

/* POSITION LEFT */
.tooltip-custom .tooltip-content.left:before {
  border-left: 5px solid #1E1E1E;
}
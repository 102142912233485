.map-avatar-wrap {
  position: absolute;
  transform: translate(-50%, 10px);
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  bottom: 15px;
  font-size: 14px;
  gap: 20px;
}

.marker-clusterer {
  z-index: 99;
}

.marker-clusterer img{
  top: -37px !important;
  left: -11px !important;
}

.marker-clusterer div {
  font-size: 18px !important;
  top: -25px !important;
  left: 1px !important;
}
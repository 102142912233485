.home-style {
  width: 100%;
  overflow: hidden;
}

.home-style .home-body {
  height: calc(100vh - 120px);
  ;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
}

.home-style .home-body .btn-incoming {
  position: absolute;
  top: 46%;
  left: 15%;
  z-index: 10;
}

.home-style .home-body .home-map {
  position: relative;
  width: 40%;
  height: inherit;
}

.home-button-container {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-style .home-body .home-content {
  width: 60%;
  padding: 16px 24px;
  height: inherit;
}

.home-style .home-body .home-content .table-content {
  max-height: calc(100vh - 310px);
  overflow-y: auto;
}

.home-style .home-body .home-content .search-table {
  margin: 24px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.home-style .message-button-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}
.bottom-right {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.bottom-right .bottom-right-col {
  display: flex;
  flex-direction: row;
  margin-right: 8px;
}

.btn-controls.disabled {
  pointer-events: none;
  border: solid 1px #ABB1B2;
}

.btn-controls.active:hover,
.btn-controls.active {
  background: #007FA1;
}

.btn-controls.active svg path:hover,
.btn-controls.active svg path {
  stroke: #FFFFFF;
}

.btn-controls.disabled svg path {
  stroke: #ABB1B2;
}

.btn-controls {
  border: solid 1px #0DA9B9;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 3px;
}

.btn-controls:hover {
  background: #E7F6F8;
}